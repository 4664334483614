import { alpha } from '@mui/material';

export const getVideoStyles = (
	useOriginalVideoSize: boolean | null | undefined,
	isNoHeroContent: boolean,
	fromHero: boolean
): React.CSSProperties => {
	const baseStyles = {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		margin: '0 auto',
	};

	if (fromHero && isNoHeroContent) {
		return {
			...baseStyles,
			position: 'relative',
			objectFit: 'cover',
			objectPosition: 'center',
			top: 0,
			left: 0,
			height: 'calc(100vh - 200px)',
			width: 'auto',
		};
	}

	return {
		...baseStyles,
		...(useOriginalVideoSize
			? { height: 'auto', width: 'auto' }
			: {
					position: 'relative',
					objectFit: 'cover',
					objectPosition: 'center',
					top: 0,
					left: 0,
					height: '100%',
					width: '100%',
			  }),
	};
};

export const videoControlsWrapperStyles = (isTabletLessScreen: boolean, sixteenByNineVideoRatio: boolean) => ({
	display: 'flex',
	gap: '0.75rem',
	position: 'absolute',
	zIndex: 10,
	pointerEvents: 'auto',
	lineHeight: 0,
	flexDirection: 'row-reverse',
	top: sixteenByNineVideoRatio ? (isTabletLessScreen ? '1.25rem' : '2rem') : 'unset',
	bottom: sixteenByNineVideoRatio ? 'unset' : isTabletLessScreen ? '1.25rem' : '2rem',
	right: '2%',
});

export const videoHoverEffect = (isVideoHoveredStyles: boolean) => {
	return {
		opacity: isVideoHoveredStyles ? 1 : 0,
		transition: 'opacity 0.4s ease',
		pointerEvents: isVideoHoveredStyles ? 'auto' : 'none',
	};
};

export const richTextVideoControlsWrapperStyles = (isExtraSmallScreen: boolean) => {
	return {
		position: 'relative',
		bottom: '2.75rem',
		...(isExtraSmallScreen && { marginLeft: '1rem' }),
	};
};

export const videoControlIconStyles = {
	cursor: 'pointer',
	color: 'text.interactive',
	backgroundColor: `${alpha('#F3F4F5', 0.9)}`,
	borderRadius: '50%',

	'&:hover, &:focus': {
		backgroundColor: `${alpha('#EBECED', 0.9)}`,
	},

	'&:active': {
		backgroundColor: `${alpha('#E3E4E5', 0.9)}`,
	},
};

export const videoIconButtonStyles = {
	padding: 0,

	'& > .MuiSvgIcon-root': {
		fontSize: '2.5rem',
		padding: '0.4rem',
	},
};

export const youtubeVideoStyles = (richTextStylePosition: string | undefined) => {
	return { position: richTextStylePosition ? richTextStylePosition : 'static' };
};

export const posterImageStyles = {
	position: 'absolute',
	width: '100%',
	height: '100%',
	objectFit: 'cover',
	objectPosition: 'center',
	zIndex: 1,
};

export const videoPlayerContentStyles = {
	position: 'relative',
	height: '100%',
	width: '100%',
};
