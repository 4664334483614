import { MutableRefObject } from 'react';

const ShortVideoDuration = 5;

export const hideControlsForShortVideos = (video: MutableRefObject<HTMLVideoElement | null>) => {
	return (video.current?.duration ?? 0) > ShortVideoDuration;
};

interface ExtendedHTMLVideoElement extends HTMLVideoElement {
	mozHasAudio?: boolean;
	webkitAudioDecodedByteCount?: number;
	audioTracks?: unknown[];
}

export const videoHasAudio = (video?: MutableRefObject<ExtendedHTMLVideoElement | null>) => {
	if (!video?.current) {
		return false;
	}

	// For Chrome
	if ('webkitAudioDecodedByteCount' in video.current && video.current.currentTime > 0) {
		return !!video.current.webkitAudioDecodedByteCount;
	}

	// Check if audio tracks exist for Safari
	if ('audioTracks' in video.current && video.current.currentTime > 0) {
		return !!video.current.audioTracks?.length;
	}

	// For Firefox
	if ('mozHasAudio' in video.current) {
		return !!video.current.mozHasAudio;
	}

	return true;
};
