import { useEffect, useRef, useState } from 'react';

import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Pause from '@mui/icons-material/Pause';
import VolumeMute from '@mui/icons-material/VolumeMute';
import VolumeOff from '@mui/icons-material/VolumeOff';

import { videoIconButtonStyles } from '@/components/VideoContent/VideoContent.styles';

import ImageWrapper from '../ImageWrapper';

import {
	ytImageStyles,
	ytVideoInnerSectionStyles,
	ytVideoSectionWrapperStyles,
	videoControlIconStyles,
	ytVideoControlStyles,
	youtubeMobileStyles,
	youtubeIframeStyles,
	videoHoverEffect,
} from './YouTubeVideoEmbed.styles';
import {
	IYouTubeVideoEmbed,
	OnStateChangeEvent,
	Player,
	PlayerEvent,
	PlayerOptions,
} from './YouTubeVideoEmbed.helpers';

declare global {
	interface Window {
		onYouTubeIframeAPIReady: () => void;
		YT: {
			Player: new (elementId: string, options: PlayerOptions) => Player;
			PlayerState: {
				ENDED: number;
			};
		};
	}
}

export default function YouTubeVideoEmbed({
	youTubeVideoId,
	youTubeVideoImage,
	isBorderRadius = true,
	autoPlay = false,
	isLooped = false,
	isMuted = false,
	fromHero = false,
	videoControlsSx,
	sixteenByNineVideoRatio = false,
}: IYouTubeVideoEmbed) {
	const [isPlaying, setIsPlaying] = useState<boolean | null>(autoPlay ? true : false);
	const [iframeSrc, setIframeSrc] = useState<string>('');
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [isNowMuted, setIsNowMuted] = useState<boolean | null>(autoPlay ? true : isMuted); // Update your existing isNowMuted state
	const [isVideoHovered, setIsVideoHovered] = useState<boolean>(false);
	const [isFirstPlayTriggered, setIsFirstPlayTriggered] = useState<boolean>(false);

	const theme = useTheme();
	const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const iframeRef = useRef<HTMLIFrameElement>(null);
	const playerRef = useRef<Player | null>(null);

	// Unique ID for each iframe to avoid conflicts with multiple players
	const uniqueId = `youtube-iframe-${youTubeVideoId}`;
	const isHoveredStylesShowed = isVideoHovered || !isFirstPlayTriggered || !isPlaying;

	// Effect to set iframe src based on provided parameters
	useEffect(() => {
		const autoplayParam = autoPlay ? 1 : 0;
		const loopParam = isLooped ? 1 : 0;
		const muteParam = isMuted || autoPlay ? 1 : 0;
		const playlistParam = isLooped ? `&playlist=${youTubeVideoId}` : '';

		const src = `https://www.youtube.com/embed/${youTubeVideoId}?autoplay=${autoplayParam}&loop=${loopParam}&mute=${muteParam}${playlistParam}&rel=0&enablejsapi=1&controls=0`;

		setIframeSrc(src);

		if (iframeRef.current) {
			iframeRef.current.src = src;
		}
	}, [youTubeVideoId, autoPlay, isLooped, isMuted]);

	useEffect(() => {
		// Function to load the YouTube IFrame API
		const loadYouTubeIframeAPI = async () => {
			return await new Promise<void>((resolve, reject) => {
				if (window.YT && window.YT.Player) {
					resolve(); // API is already loaded
				} else {
					// Create script element to load the API
					const script = document.createElement('script');
					script.id = `youtube_id_${youTubeVideoId}_${Math.floor(Math.random() * 1000000)}`;
					script.src = 'https://www.youtube.com/iframe_api';
					script.async = true;

					// Check if the API is loaded
					script.onload = () => {
						const checkYTPlayer = setInterval(() => {
							if (window.YT && window.YT.Player) {
								clearInterval(checkYTPlayer);
								resolve();
							}
						}, 100);
					};

					// Handle script load error
					script.onerror = (error) => {
						console.error('YouTube API script failed to load.', error);
						reject(new Error('YouTube API script failed to load.'));
					};

					// Append script to the document
					document.body.appendChild(script);
				}
			});
		};

		// Function to initialize the YouTube player
		const initializePlayer = async () => {
			return await new Promise<void>((resolve, reject) => {
				if (window.YT && window.YT.Player) {
					// Create new YouTube player
					playerRef.current = new window.YT.Player(uniqueId, {
						videoId: youTubeVideoId,
						playerVars: {
							autoplay: autoPlay ? 1 : 0,
							loop: isLooped ? 1 : 0,
							mute: isMuted || autoPlay ? 1 : 0,
							playlist: isLooped ? youTubeVideoId : undefined,
						},
						events: {
							onReady: onPlayerReady,
							onStateChange: handleStateChange,
						},
					});
					resolve();
				} else {
					reject(new Error('YouTube API is not available.'));
				}
			});
		};

		// Function to initialize the API and player
		const initialize = async () => {
			try {
				await loadYouTubeIframeAPI();
				await initializePlayer();
			} catch (error) {
				console.error(error);
			}
		};

		// Call the initialize function
		void initialize();

		// Cleanup function to destroy the player
		return () => {
			if (playerRef.current) {
				playerRef.current.destroy();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [youTubeVideoId, autoPlay, isLooped, isMuted]);

	// Function called when the player is ready
	const onPlayerReady = (event: PlayerEvent) => {
		const playerId = event.target.getVideoData();
		if (playerId.video_id === youTubeVideoId) {
			setIsLoading(false);
		}
		if (autoPlay) {
			event.target.playVideo();
		}
	};

	// Function to handle state changes of the player
	const handleStateChange = (event: OnStateChangeEvent) => {
		if (event.data === window.YT.PlayerState.ENDED) {
			setIsPlaying(false);
		}
	};

	// Function to handle play/pause actions
	const handlePlayPause = () => {
		const iframe = iframeRef.current;
		if (iframe) {
			const iframeWindow = iframe.contentWindow;
			const origin = new URL(iframe.src).origin;
			if (isPlaying) {
				iframeWindow?.postMessage('{"event":"command","func":"pauseVideo","args":""}', origin);
				setIsPlaying(false);
			} else {
				iframeWindow?.postMessage('{"event":"command","func":"playVideo","args":""}', origin);
				setIsPlaying(true);
			}

			setIsFirstPlayTriggered(true);
		}
	};

	const handleMuteUnmute = () => {
		const iframe = iframeRef.current;
		if (iframe) {
			const iframeWindow = iframe.contentWindow;
			const origin = new URL(iframe.src).origin;
			if (isNowMuted) {
				iframeWindow?.postMessage('{"event":"command","func":"unMute","args":""}', origin);
				setIsNowMuted(false);
			} else {
				iframeWindow?.postMessage('{"event":"command","func":"mute","args":""}', origin);
				setIsNowMuted(true);
			}
		}
	};

	if (!youTubeVideoId) {
		return <></>;
	}

	return (
		<Box
			sx={ytVideoSectionWrapperStyles}
			data-test-id="group_service_media_yt"
			onMouseOver={() => setIsVideoHovered(true)}
			onMouseOut={() => setIsVideoHovered(false)}
			onTouchStart={() => {
				setIsVideoHovered(true);
				setTimeout(() => {
					setIsVideoHovered(false);
				}, 1500);
			}}
		>
			{!isPlaying && youTubeVideoImage ? (
				<Box sx={ytVideoInnerSectionStyles(isPlaying, isLoading)}>
					<ImageWrapper
						src={youTubeVideoImage?.url as string}
						alt={youTubeVideoImage?.description ?? ''}
						style={ytImageStyles(isBorderRadius)}
					/>
				</Box>
			) : null}
			{/* Aspect ratio container for small screen */}
			<Box
				data-test-id={'youtube_aspect_ratio_for_mobile'}
				sx={youtubeMobileStyles(isExtraSmallScreen, fromHero, isBorderRadius, sixteenByNineVideoRatio)}
			>
				<iframe
					data-test-id={'youtube_embedded_iframe'}
					ref={iframeRef}
					id={uniqueId}
					src={iframeSrc}
					allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
					frameBorder="0"
					style={youtubeIframeStyles(isExtraSmallScreen, isBorderRadius, sixteenByNineVideoRatio)}
					title="YouTube Video"
				/>
			</Box>
			<Box
				sx={{
					...ytVideoControlStyles(),
					...videoControlsSx,
					...videoHoverEffect(isHoveredStylesShowed),
				}}
			>
				<IconButton
					sx={videoIconButtonStyles}
					onClick={handlePlayPause}
					aria-label={isPlaying ? 'Pause' : 'Play'}
				>
					{isPlaying ? (
						<Pause data-test-id={'pause_button'} sx={videoControlIconStyles} />
					) : (
						<PlayArrow data-test-id={'play_button'} sx={videoControlIconStyles} />
					)}
				</IconButton>
				<IconButton
					sx={videoIconButtonStyles}
					onClick={handleMuteUnmute}
					aria-label={isNowMuted ? 'Unmute' : 'Mute'}
				>
					{isNowMuted ? (
						<VolumeOff data-test-id={'volume_off_button'} sx={videoControlIconStyles} />
					) : (
						<VolumeMute data-test-id={'volume_on_button'} sx={videoControlIconStyles} />
					)}
				</IconButton>
			</Box>
		</Box>
	);
}
