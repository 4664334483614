import { useState, useRef, useEffect, Children } from 'react';

import Hls from 'hls.js';
import { Box, IconButton, useMediaQuery, useTheme } from '@mui/material';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Pause from '@mui/icons-material/Pause';
import VolumeMute from '@mui/icons-material/VolumeMute';
import VolumeOff from '@mui/icons-material/VolumeOff';

import { Maybe } from '@/types';
import { Asset, FocalPointImage as TFocalPointImage, Video } from '@/types/generated';
import ImageWrapper from '@/components/ImageWrapper';
import { resolveContentfulAspectRatio } from '@/utils/resolveContentfulAspectRatio';

import YouTubeVideoEmbed from '../YouTubeVideoEmbed';
import AspectRatioPaddingBox from '../AspectRatioPaddingBox';
import { getImageWrapperStyles } from '../Service/ServiceMedia/ServiceMedia.styles';

import {
	richTextVideoControlsWrapperStyles,
	videoControlIconStyles,
	videoControlsWrapperStyles,
	getVideoStyles,
	youtubeVideoStyles,
	videoIconButtonStyles,
	posterImageStyles,
	videoPlayerContentStyles,
	videoHoverEffect,
} from './VideoContent.styles';
import { hideControlsForShortVideos, videoHasAudio } from './VideoContent.helpers';

interface IVideoContent {
	posterImage?: Maybe<Asset>;
	focalPointPosterImage?: Maybe<TFocalPointImage>;
	video?: Maybe<Asset>;
	videoElSx?: React.CSSProperties;
	videoControlsSx?: React.CSSProperties;
	controllableVideo?: Maybe<Video>;
	children?: React.ReactNode;
	dataTestId?: string;
	richTextStylePosition?: string;
	loadVideoInPreviewMode?: boolean;
	fromHero?: boolean;
	sixteenByNineVideoRatio?: boolean;
	isNoHeroContent?: boolean;
	mediaAspectRatioSmallScreen?: Maybe<string>;
	overrodeAutoplay?: boolean;
}

export default function VideoContent({
	video,
	controllableVideo,
	posterImage,
	videoElSx = {},
	children,
	dataTestId = 'video_content',
	richTextStylePosition,
	videoControlsSx = {},
	loadVideoInPreviewMode = false,
	fromHero = false,
	sixteenByNineVideoRatio = false,
	isNoHeroContent = false,
	mediaAspectRatioSmallScreen,
	overrodeAutoplay,
}: Readonly<IVideoContent>) {
	const {
		autoPlay = false,
		isLooped = true,
		isMuted = true,
		media,
		posterMedia,
		posterUrl,
		youtubeId,
		useOriginalVideoSize = false,
		videoSource,
		hlsVideoStreamLink,
	} = Object(controllableVideo) as Video;
	const computedPosterUrl = posterUrl ?? posterMedia?.url ?? posterImage?.url ?? '';
	const computedAutoplay = Boolean(autoPlay || overrodeAutoplay);
	const [isVideoReady, setIsVideoReady] = useState(false);
	const [isPlaying, setIsPlaying] = useState<boolean>(computedAutoplay);
	const [isFirstPlayTriggered, setIsFirstPlayTriggered] = useState<boolean>(false);
	const [isPosterVisual, setIsPosterVisual] = useState<boolean>(!!computedPosterUrl);
	const [isNowMuted, setIsNowMuted] = useState<boolean | null>(computedAutoplay ? true : isMuted);
	const videoRef = useRef<HTMLVideoElement | null>(null);
	const [isLongVideo, setIsLongVideo] = useState<boolean>(false);
	const [isVideoHovered, setIsVideoHovered] = useState<boolean>(false);
	const [isAudioControlsVisible, setIsAudioControlsVisible] = useState<boolean>(true);
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
	const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
	const [userPaused, setUserPaused] = useState(false);
	const isPortraitVideo = resolveContentfulAspectRatio(mediaAspectRatioSmallScreen) === '9:16';

	const imageWrapperSx = getImageWrapperStyles(true);

	const videoUrl = media?.url ?? video?.url ?? '';

	useEffect(
		function handleVideoEvents() {
			const currentVideoRef = videoRef.current;
			const loopStartTime = 0;
			const loopEndTime = 3;

			function handleVideoEnd() {
				if (currentVideoRef && !isLooped) {
					currentVideoRef.currentTime = loopStartTime;
				}
			}

			function handleVideoReady() {
				setIsVideoReady(true);
			}

			function handleVideoPlaying() {
				setIsPlaying(true);
				setIsFirstPlayTriggered(true);
				setIsPosterVisual(false);

				// Decoding the audio script takes time and can only be done after the video starts playing.
				setTimeout(() => {
					setIsAudioControlsVisible(videoHasAudio(videoRef));
				}, 100);
			}

			function handleVideoPaused() {
				setIsPlaying(false);
			}

			function handleTimeUpdate() {
				if (loadVideoInPreviewMode && currentVideoRef && currentVideoRef.currentTime >= loopEndTime) {
					currentVideoRef.currentTime = loopStartTime;
					setIsPlaying(true);
				}
			}

			function handleLoadedMetadata() {
				setIsLongVideo(hideControlsForShortVideos(videoRef));
				setIsAudioControlsVisible(videoHasAudio(videoRef));
			}

			if (currentVideoRef) {
				currentVideoRef.addEventListener('ended', handleVideoEnd);
				currentVideoRef.addEventListener('play', handleVideoPlaying);
				currentVideoRef.addEventListener('pause', handleVideoPaused);
				currentVideoRef.addEventListener('oncanplaythrough', handleVideoReady);
				currentVideoRef.addEventListener('timeupdate', handleTimeUpdate);

				// The video is loaded
				if ((videoRef.current?.readyState ?? 0) > 1) {
					handleLoadedMetadata();
				} else {
					currentVideoRef.addEventListener('loadedmetadata', handleLoadedMetadata);
				}
			}

			return () => {
				if (currentVideoRef) {
					currentVideoRef.removeEventListener('ended', handleVideoEnd);
					currentVideoRef.removeEventListener('play', handleVideoPlaying);
					currentVideoRef.removeEventListener('pause', handleVideoPaused);
					currentVideoRef.removeEventListener('oncanplaythrough', handleVideoReady);
					currentVideoRef.removeEventListener('timeupdate', handleTimeUpdate);
					currentVideoRef.removeEventListener('loadedmetadata', handleLoadedMetadata);
				}
			};
		},
		[isLooped, loadVideoInPreviewMode]
	);

	useEffect(
		function handleScrollInOutOfView() {
			const currentVideoRef = videoRef.current;
			const shouldTriggerIfWeSeeIt = computedAutoplay || (!computedAutoplay && isFirstPlayTriggered);

			const options = { rootMargin: '0px', threshold: 0.5 };

			function handleScroll(entries: { isIntersecting: boolean }[]) {
				entries.forEach((entry) => {
					if (entry.isIntersecting && !userPaused && shouldTriggerIfWeSeeIt) {
						void currentVideoRef?.play();
					} else {
						void currentVideoRef?.pause();
					}
				});
			}

			function handleVideoReady() {
				setIsVideoReady(true);
			}

			if (!youtubeId?.trim() && currentVideoRef) {
				const observer = new IntersectionObserver(handleScroll, options);
				observer.observe(currentVideoRef);
				handleVideoReady();
				return () => {
					observer.disconnect();
				};
			}
		},
		[isVideoReady, youtubeId, userPaused, computedAutoplay, isFirstPlayTriggered]
	);

	useEffect(() => {
		if (videoRef.current && Hls.isSupported() && hlsVideoStreamLink && videoSource === 'HLS Streaming') {
			const hls = new Hls();

			if (hlsVideoStreamLink) {
				hls.loadSource(hlsVideoStreamLink);
				hls.attachMedia(videoRef.current);
			}

			return () => {
				hls.destroy();
			};
		}
	}, [hlsVideoStreamLink, videoSource]);

	function handleVideoControlClick() {
		if (videoRef.current) {
			if (isPlaying) {
				videoRef.current.pause();
				setUserPaused(true);
			} else {
				void videoRef.current.play();
				setUserPaused(false);
			}
		}
	}

	function handleMuteUnmute() {
		if (videoRef.current) {
			if (isNowMuted) {
				videoRef.current.muted = false;
				setIsNowMuted(false);
			} else {
				videoRef.current.muted = true;
				setIsNowMuted(true);
			}
		}
	}

	let hasSourceElements = false;
	Children.forEach(children, (c) => {
		if (!c) {
			return;
		}

		if ((c as React.ReactElement)?.type === 'source') {
			hasSourceElements = true;
		}
	});

	const isHoveredStylesShowed = isVideoHovered || (!isFirstPlayTriggered && !computedAutoplay) || !isPlaying;

	if (videoSource === 'Youtube' || youtubeId?.trim()) {
		if (!youtubeId?.trim()) return null;
		return (
			<AspectRatioPaddingBox
				aspectRatio={isSmallScreen ? (sixteenByNineVideoRatio ? '16:9' : '1:1') : '16:9'}
				placeholderColor="transparent"
				imageWrapperSx={imageWrapperSx}
				paddingBoxSx={youtubeVideoStyles(richTextStylePosition)}
			>
				<YouTubeVideoEmbed
					youTubeVideoId={youtubeId}
					isSmallScreen={isSmallScreen}
					isBorderRadius={false}
					youTubeVideoImage={posterMedia}
					autoPlay={computedAutoplay}
					isLooped={isLooped}
					isMuted={isMuted}
					fromHero={fromHero}
					videoControlsSx={videoControlsSx}
					sixteenByNineVideoRatio={sixteenByNineVideoRatio}
				/>
			</AspectRatioPaddingBox>
		);
	} else {
		return (
			<Box
				sx={videoPlayerContentStyles}
				onMouseOver={() => setIsVideoHovered(true)}
				onMouseOut={() => setIsVideoHovered(false)}
				onTouchStart={() => {
					setIsVideoHovered(true);
					setTimeout(() => {
						setIsVideoHovered(false);
					}, 1500);
				}}
			>
				{isPosterVisual && (
					<ImageWrapper
						src={computedPosterUrl}
						data-test-id="img_video-poster"
						alt="img_video-poster"
						sx={posterImageStyles}
					/>
				)}
				<video
					playsInline
					ref={videoRef}
					controls={false}
					muted={!!isNowMuted}
					loop={!!isLooped}
					autoPlay={computedAutoplay}
					style={{ ...videoElSx, ...getVideoStyles(useOriginalVideoSize, isNoHeroContent, fromHero) }}
					disablePictureInPicture
					data-test-id={dataTestId}
					src={!hasSourceElements ? videoUrl : undefined}
					onCanPlayThrough={() => setIsVideoReady(true)}
					id={controllableVideo?.sys?.id ?? ''}
					aria-label={media?.description ?? ''}
				>
					{children}
				</video>

				{isVideoReady && !useOriginalVideoSize && isLongVideo ? (
					<Box
						data-test-id={`group_video_controls_for_${controllableVideo?.sys?.id ?? ''}`}
						sx={[
							videoControlsWrapperStyles(isSmallScreen, isPortraitVideo),
							videoControlsSx,
							richTextStylePosition ? richTextVideoControlsWrapperStyles(isExtraSmallScreen) : {},
							videoHoverEffect(isHoveredStylesShowed),
						]}
					>
						<IconButton
							sx={videoIconButtonStyles}
							onClick={handleVideoControlClick}
							aria-label={isPlaying ? 'pause' : 'play'}
						>
							{isPlaying ? (
								<Pause data-test-id="button_icon_video_pause" sx={videoControlIconStyles} />
							) : (
								<PlayArrow data-test-id="button_icon_video_play" sx={videoControlIconStyles} />
							)}
						</IconButton>
						{isAudioControlsVisible ? (
							<IconButton
								sx={videoIconButtonStyles}
								onClick={handleMuteUnmute}
								aria-label={isNowMuted ? 'Volume on' : 'Mute'}
							>
								{isNowMuted ? (
									<VolumeOff data-test-id="button_icon_audio_off" sx={videoControlIconStyles} />
								) : (
									<VolumeMute data-test-id="button_icon_audio_on" sx={videoControlIconStyles} />
								)}
							</IconButton>
						) : null}
					</Box>
				) : null}
			</Box>
		);
	}
}
