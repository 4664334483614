import { alpha } from '@mui/material';

export const ytVideoSectionWrapperStyles = {
	position: 'relative',
	width: '100%',
	height: '100%',
};

export const ytVideoInnerSectionStyles = (isPlaying: boolean | null, isLoading: boolean) => {
	return {
		position: 'absolute',
		width: '100%',
		height: '100%',
		top: 0,
		left: 0,
		zIndex: 4,
		opacity: isPlaying && !isLoading ? 0 : 1,
		transition: 'all .5s .5s ease',
		pointerEvents: isPlaying ? 'none' : 'auto',
	};
};

export const ytImageStyles = (isBorderRadius: boolean) => ({
	position: 'absolute',
	width: '100%',
	height: '100%',
	top: 0,
	left: 0,
	zIndex: 9,
	objectFit: 'cover',
	objectPosition: 'center',
	...(isBorderRadius && { borderRadius: '0.5rem' }),
});

export const videoControlIconStyles = {
	cursor: 'pointer',
	color: 'text.interactive',
	backgroundColor: `${alpha('#F3F4F5', 0.9)}`,
	borderRadius: '50%',
	zIndex: 9999,
	pointerEvents: 'auto',

	'&:hover, &:focus': {
		backgroundColor: `${alpha('#EBECED', 0.9)}`,
	},

	'&:active': {
		backgroundColor: `${alpha('#E3E4E5', 0.9)}`,
	},
};

export const videoIconButtonStyles = {
	padding: 0,

	'& > .MuiSvgIcon-root': {
		fontSize: '2.5rem',
		padding: '0.4rem',
	},
};

export const ytVideoControlStyles = () => ({
	position: 'absolute',
	display: 'flex',
	gap: '0.75rem',
	bottom: '0.75rem',
	left: '1rem',
	flexDirection: 'row',
	zIndex: 10,
	pointerEvents: 'auto',
});

export const videoHoverEffect = (isVideoHoveredStyles: boolean) => {
	return {
		opacity: isVideoHoveredStyles ? 1 : 0,
		transition: 'opacity 0.4s ease',
		pointerEvents: isVideoHoveredStyles ? 'auto' : 'none',
	};
};
export const youtubeMobileStyles = (
	isExtraSmallScreen: boolean,
	fromHero: boolean,
	isBorderRadius: boolean,
	sixteenByNineVideoRatio: boolean
) => ({
	...(isExtraSmallScreen &&
		!fromHero && {
			position: 'relative',
			width: '100%',
			paddingBottom: sixteenByNineVideoRatio ? '56.25%' : '100%', // 16:9 ratio for ServiceTemplate(which is supposed to be deprecated) or BirdeyeReviews, else 1:1 aspect ratio (height equals width)
			height: 0,
			overflow: 'hidden',
			...(isBorderRadius && { borderRadius: '0.5rem' }),
		}),
});

export const youtubeIframeStyles = (
	isExtraSmallScreen: boolean,
	isBorderRadius: boolean,
	sixteenByNineVideoRatio: boolean
) => ({
	position: 'absolute' as const, //typescript needs this definition for style attribute
	top: isExtraSmallScreen ? '50%' : 0,
	left: isExtraSmallScreen ? '50%' : 0,
	width: '100%',
	height: '100%',
	pointerEvents: 'none' as const, //typescript needs this definition for style attribute
	...(isExtraSmallScreen && {
		transform: sixteenByNineVideoRatio ? 'translate(-50%, -50%) scale(1.0)' : 'translate(-50%, -50%) scale(1.8)', // Adjust the scale as needed to remove black bars for 1:1 ratio
	}),
	...(isBorderRadius && { borderRadius: '0.5rem' }),
});
